* {
  font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
  box-sizing: border-box;
}

html,
body,
#root {
  font-weight: 300;
  margin: 0;
  width: 100%;
  height: 100%;
  background: #1795d4;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

